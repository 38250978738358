import React, { useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { ConvertPointsStepper } from './components/ConvertPointsStepper';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@material-ui/core';
import { PointsStep } from './components/PointsStep';
import { InformationStep } from './components/InformationStep';
import { DeclarationStep } from './components/DeclarationStep';
import { SummaryStep } from './components/SummaryStep';
import useConvertPointsSteps from '@spider:src/hooks/UseConvertPointsSteps';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearConvertPointDataStep,
  clearDeclarationDataStep,
  clearInformationDataStep,
  clearStepperDataState,
  setCommandId,
  setConvertPointDataStep,
  setDeclarationDataStep,
  setInformationDataStep,
} from '@spider:src/features/convertPoint/stepperData/slices';
import useSessionStorageSliceForm from '@spider:src/hooks/UseSessionStorageSliceForm';
import useHandleToast from '@src/Spider/hooks/UseHandleToast';
import {
  clearSimulationState,
  resetSimulation,
  simulateRewardsAllocationsKeyClear,
  simulateRewardsAllocationsKeyStart,
} from '@spider:src/features/rewardAllocations/simulateRewardAllocation/slices';
import { useIntl } from 'react-intl';
import useConvertPointsHelper from '@spider:src/hooks/CommandReward/UseConvertPointsHelper';
import { ReactComponent as ErrorIcon } from '@spider:src/assets/img/error-icon.svg';
import { useAuth } from '@src/auth';
import { Loader } from '@src/components';
import CloseIcon from '@mui/icons-material/Close';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  rootContent: {
    '&.MuiPaper-root': {
      boxShadow: 'none',
      padding: '1.5rem',
    },
  },
};

const ConvertPoints = ({ ...props }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const { contract } = useParams();
  const { hierarchyNodeUser } = useAuth();

  const activeStepFromSessionStorage = sessionStorage.getItem('activeStep');
  const rewardFromSessionStorage = sessionStorage.getItem('reward');
  const [activeStep, setActiveStep] = React.useState(
    activeStepFromSessionStorage !== null
      ? parseInt(activeStepFromSessionStorage)
      : 0,
  );

  const simulationRewardAllocation = useSelector(
    state => state.simulateRewardsAllocationsKey,
  );
  const stepperData = useSelector(state => state.getStepperDataKey);
  const { steps } = useConvertPointsSteps();
  const { isDataSavable, currentBalanceTooLow, minimumPoints, maximumPoints } =
    useConvertPointsHelper();
  const [saveInit, setSaveInit] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const closeDialog = () => {
    goHome();
    setOpen(false);
  };
  const openDialog = () => setOpen(true);

  const goHome = () => {
    history.push(`/nodes/${contract}/home`);
  };

  const nextStep = () => {
    if (activeStep === steps.length) return;
    history.push(steps[activeStep + 1].src, { shallow: true });
    setActiveStep(activeStep + 1);
  };

  const previousStep = () => {
    if (activeStep === 0) {
      goHome();
      return;
    }
    setActiveStep(activeStep - 1);
    history.push(steps[activeStep - 1].src, { shallow: true });
  };

  const goConvert = () => {
    history.push(`/nodes/${contract}/conversion-points`, { shallow: true });
    setActiveStep(0);
  };

  const goInformation = () => {
    history.push(`/nodes/${contract}/informations-personnelles`, {
      shallow: true,
    });
    setActiveStep(
      steps.findIndex(
        step => step.src === `/nodes/${contract}/informations-personnelles`,
      ),
    );
  };

  useHandleToast(
    simulationRewardAllocation,
    dispatch,
    simulateRewardsAllocationsKeyClear,
    null,
    'spider.convertPoints.stepper.simulate.error',
  );

  const { initDefault: initPointByDefault, saveStateData: savePointStateData } =
    useSessionStorageSliceForm({
      key: 'convertPointsStep',
      isDataSavable,
      sliceKey: 'getStepperDataKey',
      sliceProp: 'convertPointsDataStep',
      setClear: clearConvertPointDataStep,
      sliceSetter: setConvertPointDataStep,
      automaticInit: true,
    });

  const { initDefault: initInformationByDefault } = useSessionStorageSliceForm({
    key: 'informationStep',
    sliceKey: 'getStepperDataKey',
    sliceProp: 'informationDataStep',
    setClear: clearInformationDataStep,
    sliceSetter: setInformationDataStep,
    automaticInit: true,
  });

  const { initDefault: initDeclarationByDefault } = useSessionStorageSliceForm({
    key: 'declarationStep',
    sliceKey: 'getStepperDataKey',
    sliceProp: 'declarationDataStep',
    setClear: clearDeclarationDataStep,
    sliceSetter: setDeclarationDataStep,
    automaticInit: true,
  });

  const simulate = pointToConvert => {
    if (!stepperData.commandId || !hierarchyNodeUser) return;

    dispatch(
      simulateRewardsAllocationsKeyStart({
        rewardAllocationId: stepperData.commandId._context.allocation.uuid,
        pointToConvert,
        userId: hierarchyNodeUser.uuid,
        initializeSimulation: true,
      }),
    );
  };

  useEffect(() => {
    if (currentBalanceTooLow) openDialog();
  }, [currentBalanceTooLow]);

  useEffect(() => {
    if (rewardFromSessionStorage) {
      dispatch(setCommandId(JSON.parse(rewardFromSessionStorage)));
    }
    sessionStorage.setItem('activeStep', activeStep);
  }, [activeStep]);

  useEffect(() => {
    // todo: construct slice from the sessionStorage IMPORTANT
    initPointByDefault();
    initInformationByDefault();
    initDeclarationByDefault();

    return () => {
      sessionStorage.removeItem('activeStep');
      sessionStorage.removeItem('reward');
      sessionStorage.removeItem('convertPointsStep');
      sessionStorage.removeItem('informationStep');
      sessionStorage.removeItem('declarationStep');
      dispatch(clearStepperDataState());
      dispatch(clearSimulationState());
    };
  }, []);

  useEffect(() => {
    if (
      simulationRewardAllocation.initializeSimulation ||
      simulationRewardAllocation.simulation !== null
    )
      return;
    simulate(maximumPoints);
  }, [stepperData.commandId]);

  useEffect(() => {
    if (!simulationRewardAllocation.initializeSimulation) return;
    if (
      saveInit &&
      (!stepperData.convertPointsDataStep ||
        !parseInt(stepperData.convertPointsDataStep.points))
    ) {
      dispatch(resetSimulation());
      return;
    }

    const pointToConvert = stepperData.convertPointsDataStep
      ? parseInt(stepperData.convertPointsDataStep.points)
      : !simulationRewardAllocation.simulation
        ? maximumPoints
        : simulationRewardAllocation.simulation.max_points_usable;

    if (!saveInit && !stepperData.convertPointsDataStep) {
      savePointStateData({ points: pointToConvert }, true);
      setSaveInit(true);
    } else if (stepperData.convertPointsDataStep) {
      setSaveInit(true);
    }

    simulate(pointToConvert);
  }, [
    simulationRewardAllocation.initializeSimulation,
    stepperData.convertPointsDataStep,
    hierarchyNodeUser,
  ]);

  return (
    <div className={props.classes.root}>
      <ConvertPointsStepper
        steps={steps}
        activeStep={activeStep}
        previousStep={previousStep}
      />

      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle disableTypography style={{ alignItems: 'baseline' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              className={'icon light'}
              onClick={closeDialog}
            >
              <CloseIcon />
            </Button>
          </div>
          <ErrorIcon style={{ margin: 'auto' }} />
          <Typography
            variant={'h1'}
            component={'h1'}
            className={'underline-center'}
            style={{ textAlign: 'center' }}
          >
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointBalanceDialog.title',
            })}
          </Typography>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {intl
              .formatMessage({
                id: 'spider.convertPoints.stepper.pointBalanceDialog.message',
              })
              .format(
                minimumPoints,
                stepperData.commandId ? stepperData.commandId.name : '',
              )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            size={'small'}
            color={'primary'}
            variant={'contained'}
            onClick={closeDialog}
          >
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointBalanceDialog.closeAction',
            })}
          </Button>
        </DialogActions>
      </Dialog>

      {saveInit &&
      activeStep !== null &&
      steps &&
      steps.length > 0 &&
      steps[activeStep] ? (
        <Paper className={props.classes.rootContent}>
          {steps[activeStep].src === `/nodes/${contract}/conversion-points` && (
            <PointsStep nextStep={nextStep} />
          )}
          {steps[activeStep].src ===
            `/nodes/${contract}/informations-personnelles` && (
            <InformationStep nextStep={nextStep} previousStep={previousStep} />
          )}
          {steps[activeStep].src ===
            `/nodes/${contract}/declaration-urssaf` && (
            <DeclarationStep nextStep={nextStep} previousStep={previousStep} />
          )}
          {steps[activeStep].src ===
            `/nodes/${contract}/recapitulatif-commande` && (
            <SummaryStep
              nextStep={nextStep}
              previousStep={previousStep}
              goConvert={goConvert}
              goInformation={goInformation}
            />
          )}
        </Paper>
      ) : (
        <Loader centered />
      )}
    </div>
  );
};

export default withStyles(styles)(ConvertPoints);
